<template>
  <HomeLayout>
    <b-container
      class="d-flex flex-column align-items-start justify-content-start px-0 add-customer-view"
    >
      <!-- table container row -->
      <b-row
        class="d-flex flex-row align-items-stretch justify-content-center table-card-row w-100"
        no-gutters
      >
        <b-card no-body class="shadow-sm border-0 table-container-card w-100">
          <b-row
            class="d-flex flex-row align-items-center justify-content-between w-75 my-1 my-md-2 pl-4 pt-4"
            no-gutters
          >
            <!-- Contact Title row  -->
            <b-row
              class="d-flex flex-row align-items-start justify-content-start px-2 pt-2"
              no-gutters
            >
              <b-col
                class="d-flex flex-column align-items-start justify-content-start"
              >
                <h6
                  class="text-left text-gray-750 font-weight-bold font-primary"
                >
                  Contact
                </h6>
              </b-col>
            </b-row>
            <!-- full row input (Customer) -->
            <b-row
              class="d-flex flex-row align-items-start justify-content-end w-100 pt-3 px-2"
              no-gutters
            >
              <b-col cols="4">
                <span
                  v-if="isSupplier"
                  class="text-left text-dark font-primary font-weight-normal"
                  >Supplier</span
                >
                <span
                  v-else
                  class="text-left text-dark font-primary font-weight-normal"
                  >Customer</span
                >
              </b-col>
              <b-col cols="8">
                <FormInput
                  groupId="Customeruser-name-group"
                  id="Customeruser-name-group-input"
                  class="text-prime-gray rounded-2"
                  :state="customerNameError.status"
                  :invalidFeedback="`${customerNameError.message}`"
                  label="Business or a person"
                  v-model="customerName"
                  isRequired
                  :whiteBG="false"
                  trim
                  form="login-form"
                  type="text"
                ></FormInput>
              </b-col>
            </b-row>
            <!-- full row input (Supplier Type) -->
            <b-row
              v-if="isSupplier"
              class="d-flex flex-row align-items-start justify-content-end w-100 px-2"
              no-gutters
            >
              <b-col cols="4">
                <span
                  class="text-left text-dark font-primary font-weight-normal"
                  >Supplier type</span
                >
              </b-col>
              <b-col cols="8">
                <FormSelect
                  groupId="Supplier-s-group"
                  id="Supplier-s-group-input"
                  class="text-prime-gray rounded-2"
                  label="Supplier type"
                  v-model="SuplierType"
                  invalidFeedback="Please fill out this field"
                  :whiteBG="false"
                  :options="SupplierTypeList"
                  form="login-form"
                ></FormSelect>
              </b-col>
            </b-row>
            <!-- full row input (Full name) -->
            <b-row
              class="d-flex flex-row align-items-start justify-content-end w-100 px-2"
              no-gutters
            >
              <b-col cols="4">
                <span
                  class="text-left text-dark font-primary font-weight-normal"
                  >Full name</span
                >
              </b-col>
              <b-col cols="8">
                <FormInput
                  groupId="Full2user-name-group"
                  id="Full2user-name-group-input"
                  class="text-prime-gray rounded-2"
                  label="Full name"
                  v-model="fullName"
                  :state="fullNameError.status"
                  :invalidFeedback="fullNameError.message"
                  isRequired
                  :whiteBG="false"
                  trim
                  form="login-form"
                  type="text"
                ></FormInput>
              </b-col>
            </b-row>
            <!-- full row input (Phone) -->
            <b-row
              class="d-flex flex-row align-items-start justify-content-end w-100 px-2"
              no-gutters
            >
              <b-col cols="4">
                <span
                  class="text-left text-dark font-primary font-weight-normal"
                  >Phone</span
                >
              </b-col>
              <b-col cols="8">
                <FormInput
                  groupId="Phone3user-name-group"
                  id="Phone3user-name-group-input"
                  class="text-prime-gray rounded-2"
                  label="Phone"
                  v-model="customerPhone"
                  :state="customerPhoneError.status"
                  :invalidFeedback="customerPhoneError.message"
                  @keypress="isTelephoneInput($event)"
                  isRequired
                  :whiteBG="false"
                  trim
                  form="login-form"
                  type="tel"
                ></FormInput>
              </b-col>
            </b-row>
            <!-- full row input (E-mail) -->
            <b-row
              class="d-flex flex-row align-items-start justify-content-end w-100 px-2"
              no-gutters
            >
              <b-col cols="4">
                <span
                  class="text-left text-dark font-primary font-weight-normal"
                  >E-mail</span
                >
              </b-col>
              <b-col cols="8">
                <FormInput
                  groupId="mail23user-name-group"
                  id="mail23user-name-group-input"
                  class="text-prime-gray rounded-2"
                  :state="customerEmailError.status"
                  :invalidFeedback="`${customerEmailError.message}`"
                  label="E-mail"
                  v-model="customerEmail"
                  isRequired
                  :whiteBG="false"
                  trim
                  form="login-form"
                  type="text"
                ></FormInput>
              </b-col>
            </b-row>
            <!-- full row input (Payment Terms) -->
            <b-row
              v-if="isSupplier"
              class="d-flex flex-row align-items-start justify-content-end w-100 px-2"
              no-gutters
            >
              <b-col cols="4">
                <span
                  class="text-left text-dark font-primary font-weight-normal"
                  >Payment term</span
                >
              </b-col>
              <b-col cols="8">
                <FormSelect
                  groupId="Payment-s-group"
                  id="Payment-s-group-input"
                  class="text-prime-gray rounded-2"
                  label="Payment term"
                  v-model="PaymentTerm"
                  invalidFeedback="Please fill out this field"
                  :whiteBG="false"
                  :options="PaymentTermList"
                  form="login-form"
                ></FormSelect>
              </b-col>
            </b-row>
          </b-row>

          <b-row
            class="d-flex flex-row align-items-center justify-content-between w-75 my-1 my-md-2 pl-4 py-2"
            no-gutters
          >
            <!-- Billing Address Title row  -->
            <b-row
              class="d-flex flex-row align-items-start justify-content-start px-2 pt-4"
              no-gutters
            >
              <b-col
                class="d-flex flex-column align-items-start justify-content-start"
              >
                <h6
                  class="text-left text-gray-750 font-weight-bold font-primary"
                >
                  Billing Address
                </h6>
              </b-col>
            </b-row>
            <!-- full row input (Country) -->
            <b-row
              class="d-flex flex-row align-items-start justify-content-end w-100 pt-4 px-2"
              no-gutters
            >
              <b-col cols="4">
                <span
                  class="text-left text-dark font-primary font-weight-normal"
                  >Country</span
                >
              </b-col>
              <b-col cols="8">
                <!--                <FormInput-->
                <!--                  groupId="Country-s-group"-->
                <!--                  id="Country-s-group-input"-->
                <!--                  class="text-prime-gray rounded-2 mt-2"-->
                <!--                  label="Choose a country"-->
                <!--                  v-model="billingAddress.addressCountry"-->
                <!--                  isRequired-->
                <!--                  :whiteBG="false"-->
                <!--                  :isDisabled="true"-->
                <!--                  trim-->
                <!--                  form="login-form"-->
                <!--                  type="text"-->
                <!--                ></FormInput>-->
                <FormSelect
                  groupId="Country-s-group"
                  id="Country-s-group-input"
                  class="text-prime-gray rounded-2"
                  label="Country"
                  v-model="billingCountry"
                  :state="
                    billingAddress.addressCountry != null
                      ? billingAddress.addressCountry == 0
                        ? false
                        : true
                      : null
                  "
                  invalidFeedback="Please fill out this field"
                  :whiteBG="false"
                  :options="countryList"
                  form="login-form"
                ></FormSelect>
                <!-- <FormSelect
                  groupId="fiscalyear-group"
                  id="fiscalyear-group-input"
                  class="text-prime-gray rounded-2"
                  label="Sri Lanka"
                  v-model="billingAddress.addressCountry"
                  :whiteBG="false"
                  :options="addressCountryOptions"
                  form="login-form"
                ></FormSelect> -->
              </b-col>
            </b-row>
            <!-- full row input (No :) -->
            <b-row
              class="d-flex flex-row align-items-start justify-content-end w-100 px-2"
              no-gutters
            >
              <b-col cols="4">
                <span
                  class="text-left text-dark font-primary font-weight-normal"
                  >No :</span
                >
              </b-col>
              <b-col cols="8">
                <FormInput
                  groupId="No2-name-group"
                  id="No2-name-group-input"
                  class="text-prime-gray rounded-2"
                  label="Number"
                  v-model="billingAddress.addressNum"
                  :state="
                    billingAddress.addressNum != null
                      ? billingAddress.addressNum == 0
                        ? false
                        : true
                      : null
                  "
                  invalidFeedback="Please fill out this field"
                  isRequired
                  :whiteBG="false"
                  trim
                  form="login-form"
                  type="text"
                ></FormInput>
              </b-col>
            </b-row>
            <!-- full row input (Street) -->
            <b-row
              class="d-flex flex-row align-items-start justify-content-end w-100 px-2"
              no-gutters
            >
              <b-col cols="4">
                <span
                  class="text-left text-dark font-primary font-weight-normal"
                  >Street</span
                >
              </b-col>
              <b-col cols="8">
                <FormInput
                  groupId="Street2-name-group"
                  id="Street2-name-group-input"
                  class="text-prime-gray rounded-2"
                  label="Street"
                  v-model="billingAddress.addressStreet"
                  :state="
                    billingAddress.addressStreet != null
                      ? billingAddress.addressStreet == 0
                        ? false
                        : true
                      : null
                  "
                  invalidFeedback="Please fill out this field"
                  isRequired
                  :whiteBG="false"
                  trim
                  form="login-form"
                  type="text"
                ></FormInput>
              </b-col>
            </b-row>
            <!-- full row input (City) -->
            <b-row
              class="d-flex flex-row align-items-start justify-content-end w-100 px-2"
              no-gutters
            >
              <b-col cols="4">
                <span
                  class="text-left text-dark font-primary font-weight-normal"
                  >City</span
                >
              </b-col>
              <b-col cols="8">
                <FormInput
                  groupId="City2-name-group"
                  id="City2-name-group-input"
                  class="text-prime-gray rounded-2"
                  label="City"
                  v-model="billingAddress.addressCity"
                  :state="
                    billingAddress.addressCity != null
                      ? billingAddress.addressCity == 0
                        ? false
                        : true
                      : null
                  "
                  invalidFeedback="Please fill out this field"
                  isRequired
                  :whiteBG="false"
                  trim
                  form="login-form"
                  type="text"
                ></FormInput>
              </b-col>
            </b-row>
            <!-- full row input (Zip Code) -->
            <b-row
              class="d-flex flex-row align-items-start justify-content-end w-100 px-2"
              no-gutters
            >
              <b-col cols="4">
                <span
                  class="text-left text-dark font-primary font-weight-normal"
                  >Zip Code</span
                >
              </b-col>
              <b-col cols="8">
                <FormInput
                  groupId="user-Zip Code-group"
                  id="user-Zip Code-group-input"
                  class="text-prime-gray rounded-2"
                  label="Zip Code"
                  v-model="billingAddress.addressZip"
                  :state="
                    billingAddress.addressZip != null
                      ? billingAddress.addressZip == 0
                        ? false
                        : true
                      : null
                  "
                  invalidFeedback="Please fill out this field"
                  isRequired
                  :whiteBG="false"
                  trim
                  form="login-form"
                  type="number"
                ></FormInput>
              </b-col>
            </b-row>
            <!-- full row input (Province) -->
            <b-row
              class="d-flex flex-row align-items-start justify-content-end w-100 px-2"
              no-gutters
            >
              <b-col cols="4">
                <span
                  class="text-left text-dark font-primary font-weight-normal"
                  >Province</span
                >
              </b-col>
              <b-col cols="8">
                <FormSelect
                  groupId="Province-group"
                  id="Province-group-input"
                  class="text-prime-gray rounded-2"
                  label="Province"
                  v-model="billingAddress.addressProvince"
                  :state="
                    billingAddress.addressProvince != null
                      ? billingAddress.addressProvince == 0
                        ? false
                        : true
                      : null
                  "
                  invalidFeedback="Please fill out this field"
                  :whiteBG="false"
                  :options="filteredProvinceListBilling"
                  :filtered-options="filteredProvinceListBilling"
                  form="login-form"
                ></FormSelect>
              </b-col>
            </b-row>
            <!-- Shipping Address Title row  -->
            <b-row
              class="d-flex flex-row align-items-start justify-content-start px-2 pt-3"
              no-gutters
            >
              <b-col
                class="d-flex flex-column align-items-start justify-content-start"
              >
                <h6
                  class="text-left text-gray-750 font-weight-bold font-primary"
                >
                  Shipping Address
                </h6>
              </b-col>
            </b-row>
            <!-- shipping address checkbox  -->
            <b-row
              class="d-flex flex-row align-items-start justify-content-start w-100 px-2 py-3"
              no-gutters
            >
              <b-col
                class="d-flex flex-column align-items-start justify-content-start"
              >
                <b-form-checkbox
                  id="checkbox-1"
                  v-model="shippingStatus"
                  name="checkbox-1"
                  value="isBilling"
                  unchecked-value="isShipping"
                >
                  Same as billing address
                </b-form-checkbox>
              </b-col>
            </b-row>
            <!-- shipping address form  -->
            <template v-if="this.shippingStatus == `isShipping`">
              <!-- full row input (Full name) -->
              <b-row
                class="d-flex flex-row align-items-start justify-content-end w-100 px-2 pt-4"
                no-gutters
              >
                <b-col cols="4">
                  <span
                    class="text-left text-dark font-primary font-weight-normal"
                    >Full name</span
                  >
                </b-col>
                <b-col cols="8">
                  <FormInput
                    groupId="Full-user-name-group"
                    id="Full-user-name-group-input"
                    class="text-prime-gray rounded-2"
                    label="Full name"
                    v-model="shippingName"
                    :state="shippingNameError.status"
                    :invalidFeedback="shippingNameError.message"
                    isRequired
                    :whiteBG="false"
                    trim
                    form="login-form"
                    type="text"
                  ></FormInput>
                </b-col>
              </b-row>
              <!-- full row input (Phone) -->
              <b-row
                class="d-flex flex-row align-items-start justify-content-end w-100 px-2"
                no-gutters
              >
                <b-col cols="4">
                  <span
                    class="text-left text-dark font-primary font-weight-normal"
                    >Phone</span
                  >
                </b-col>
                <b-col cols="8">
                  <FormInput
                    groupId="Phone-name-group"
                    id="Phone-name-group-input"
                    class="text-prime-gray rounded-2"
                    label="Phone"
                    v-model="shippingPhone"
                    :state="shippingPhoneError.status"
                    :invalidFeedback="shippingPhoneError.message"
                    @keypress="isTelephoneInput($event)"
                    isRequired
                    :whiteBG="false"
                    trim
                    form="login-form"
                    type="tel"
                  ></FormInput>
                </b-col>
              </b-row>
              <!-- full row input (E-mail) -->
              <b-row
                class="d-flex flex-row align-items-start justify-content-end w-100 px-2 pb-4"
                no-gutters
              >
                <b-col cols="4">
                  <span
                    class="text-left text-dark font-primary font-weight-normal"
                    >E-mail</span
                  >
                </b-col>
                <b-col cols="8">
                  <FormInput
                    groupId="mail-name-group"
                    id="mail-name-group-input"
                    class="text-prime-gray rounded-2"
                    :state="shippingEmailError.status"
                    :invalidFeedback="`${shippingEmailError.message}`"
                    label="E-mail"
                    v-model="shippingEmail"
                    isRequired
                    :whiteBG="false"
                    trim
                    form="login-form"
                    type="text"
                  ></FormInput>
                </b-col>
              </b-row>
              <!-- full row input (Country) -->
              <b-row
                class="d-flex flex-row align-items-start justify-content-end w-100 pt-4 px-2"
                no-gutters
              >
                <b-col cols="4">
                  <span
                    class="text-left text-dark font-primary font-weight-normal"
                    >Country</span
                  >
                </b-col>
                <b-col cols="8">
                  <!--                  <FormInput-->
                  <!--                    groupId="timezone-group"-->
                  <!--                    id="Country-group-input"-->
                  <!--                    class="text-prime-gray rounded-2 mt-2"-->
                  <!--                    label="Choose a country"-->
                  <!--                    v-model="shippingAddress.addressCountry"-->
                  <!--                    isRequired-->
                  <!--                    :whiteBG="false"-->
                  <!--                    :isDisabled="true"-->
                  <!--                    trim-->
                  <!--                    form="login-form"-->
                  <!--                    type="text"-->
                  <!--                  ></FormInput>-->
                  <FormSelect
                    groupId="timezone-group"
                    id="Country-group-input"
                    class="text-prime-gray rounded-2"
                    label="Choose a country"
                    v-model="shippingCountry"
                    :state="
                      shippingAddress.addressCountry != null
                        ? shippingAddress.addressCountry == 0
                          ? false
                          : true
                        : null
                    "
                    invalidFeedback="Please fill out this field"
                    :whiteBG="false"
                    :options="countryList"
                    form="login-form"
                  ></FormSelect>
                  <!-- <FormSelect
                    groupId="fiscalyear-group"
                    id="fiscalyear-group-input"
                    class="text-prime-gray rounded-2"
                    label="Sri Lanka"
                    v-model="shippingAddress.addressCountry"
                    :whiteBG="false"
                    isDisabled="true"
                    :options="addressCountryOptions"
                    form="login-form"
                  ></FormSelect> -->
                </b-col>
              </b-row>
              <!-- full row input (No :) -->
              <b-row
                class="d-flex flex-row align-items-start justify-content-end w-100 px-2"
                no-gutters
              >
                <b-col cols="4">
                  <span
                    class="text-left text-dark font-primary font-weight-normal"
                    >No :</span
                  >
                </b-col>
                <b-col cols="8">
                  <FormInput
                    groupId="No-name-group"
                    id="No-name-group-input"
                    class="text-prime-gray rounded-2"
                    label="Number"
                    v-model="shippingAddress.addressNum"
                    :state="
                      shippingAddress.addressNum != null
                        ? shippingAddress.addressNum == 0
                          ? false
                          : true
                        : null
                    "
                    invalidFeedback="Please fill out this field"
                    isRequired
                    :whiteBG="false"
                    trim
                    form="login-form"
                    type="text"
                  ></FormInput>
                </b-col>
              </b-row>
              <!-- full row input (Street) -->
              <b-row
                class="d-flex flex-row align-items-start justify-content-end w-100 px-2"
                no-gutters
              >
                <b-col cols="4">
                  <span
                    class="text-left text-dark font-primary font-weight-normal"
                    >Street</span
                  >
                </b-col>
                <b-col cols="8">
                  <FormInput
                    groupId="Street-name-group"
                    id="Street-name-group-input"
                    class="text-prime-gray rounded-2"
                    label="Street"
                    v-model="shippingAddress.addressStreet"
                    :state="
                      shippingAddress.addressStreet != null
                        ? shippingAddress.addressStreet == 0
                          ? false
                          : true
                        : null
                    "
                    :invalidFeedback="test.message"
                    isRequired
                    :whiteBG="false"
                    trim
                    form="login-form"
                    type="text"
                  ></FormInput>
                </b-col>
              </b-row>
              <!-- full row input (City) -->
              <b-row
                class="d-flex flex-row align-items-start justify-content-end w-100 px-2"
                no-gutters
              >
                <b-col cols="4">
                  <span
                    class="text-left text-dark font-primary font-weight-normal"
                    >City</span
                  >
                </b-col>
                <b-col cols="8">
                  <FormInput
                    groupId="City-name-group"
                    id="City-name-group-input"
                    class="text-prime-gray rounded-2"
                    label="City"
                    v-model="shippingAddress.addressCity"
                    :state="
                      shippingAddress.addressCity != null
                        ? shippingAddress.addressCity == 0
                          ? false
                          : true
                        : null
                    "
                    invalidFeedback="Please fill out this field"
                    isRequired
                    :whiteBG="false"
                    trim
                    form="login-form"
                    type="text"
                  ></FormInput>
                </b-col>
              </b-row>
              <!-- full row input (Zip Code) -->
              <b-row
                class="d-flex flex-row align-items-start justify-content-end w-100 px-2"
                no-gutters
              >
                <b-col cols="4">
                  <span
                    class="text-left text-dark font-primary font-weight-normal"
                    >Zip Code</span
                  >
                </b-col>
                <b-col cols="8">
                  <FormInput
                    groupId="Zip-Code-name-group"
                    id="Zip-Code-name-group-input"
                    class="text-prime-gray rounded-2"
                    label="Zip Code"
                    v-model="shippingAddress.addressZip"
                    :state="
                      shippingAddress.addressZip != null
                        ? shippingAddress.addressZip == 0
                          ? false
                          : true
                        : null
                    "
                    invalidFeedback="Please fill out this field"
                    isRequired
                    :whiteBG="false"
                    trim
                    form="login-form"
                    type="text"
                  ></FormInput>
                </b-col>
              </b-row>
              <!-- full row input (Province) -->
              <b-row
                class="d-flex flex-row align-items-start justify-content-end w-100 px-2"
                no-gutters
              >
                <b-col cols="4">
                  <span
                    class="text-left text-dark font-primary font-weight-normal"
                    >Province</span
                  >
                </b-col>
                <b-col cols="8">
                  <FormSelect
                    groupId="fiscalyear-group"
                    id="Province-group-input"
                    class="text-prime-gray rounded-2"
                    label="Province"
                    v-model="shippingAddress.addressProvince"
                    :state="
                      shippingAddress.addressProvince != null
                        ? shippingAddress.addressProvince == 0
                          ? false
                          : true
                        : null
                    "
                    invalidFeedback="Please fill out this field"
                    :whiteBG="false"
                    :options="filteredProvinceListShipping"
                    :filtered-options="filteredProvinceListShipping"
                    form="login-form"
                  ></FormSelect>
                </b-col>
              </b-row>
            </template>
          </b-row>
          <!-- form action buttons  -->
          <b-row
            class="d-flex flex-row align-items-center justify-content-center justify-content-sm-end px-4 mb-4 w-100"
          >
            <b-button
              variant="gray-550"
              pill
              class="my-2 my-sm-0 mr-0 mr-sm-2 border-0 bg-ghost-white action-btn"
              @click="cancel"
            >
              Cancel
            </b-button>
            <b-button
              variant="main-green"
              pill
              class="my-2 my-sm-0 ml-0 ml-sm-2 border-0 action-btn-save"
              @click="addCustomer"
            >
              {{ this.$route.meta.isEdit ? "Update" : "Save" }}
            </b-button>
          </b-row>
        </b-card>
      </b-row>
    </b-container>
  </HomeLayout>
</template>
<script>
// @ is an alias to /src
import HomeLayout from "@/layout/HomeLayout";
import FormInput from "@/components/Form/FormInput";
import FormSelect from "@/components/Form/FormSelect";
import CheckEmail from "@/util/CheckEmail";

import isValidContactNumber from "@/util/isValidContactNumber";

import isTelephoneInput from "@/mixin/isTelephoneInput.js";

// services
import {
  AddCustomer,
  GetSingleCustomer,
  GetSingleSupplier,
  UpdateCustomer,
  UpdateSupplier,
  AddSupplier,
} from "@/services/customers.service";

export default {
  name: "CustomersCreate",
  mixins: [isTelephoneInput],
  components: {
    HomeLayout,
    FormInput,
    FormSelect,
  },
  data() {
    return {
      customerName: null,
      SuplierType: null,
      fullName: null,
      customerPhone: null,
      customerEmail: null,
      PaymentTerm: null,
      billingCountry: null,
      shippingCountry: null,
      billingAddress: {
        addressNum: null,
        addressStreet: null,
        addressCity: null,
        addressZip: null,
        addressProvince: null,
        addressCountry: null,
      },
      shippingAddress: {
        addressNum: null,
        addressStreet: null,
        addressCity: null,
        addressZip: null,
        addressProvince: null,
        addressCountry: null,
      },
      shippingName: null,
      shippingPhone: null,
      shippingEmail: null,
      shippingStatus: "isBilling",
      NewzelandProvinceList: [
        "Northland",
        "Auckland",
        "Waikato",
        "Bay of Plenty",
        "Gisborne",
        "Hawke's Bay",
        "Taranaki",
        "Manawatu-Whanganui",
        "Wellington",
        "Tasman",
        "Nelson",
        "Marlborough",
        "West Coast",
        "Canterbury",
        "Otago",
        "Southland",
      ],
      AusiProvinceList: [
        "New South Wales",
        "Queensland",
        "Northern Territory",
        "Western Australia",
        "South Australia",
        "Victoria",
        "Australian Capital Territory",
        "Tasmania",
      ],

      // addressCountryOptions: ["Sri Lanka"],
      isEditCustomer: null,
      customerNameError: {
        status: null,
        message: "",
      },
      fullNameError: {
        status: null,
        message: "",
      },
      customerPhoneError: {
        status: null,
        message: "",
      },
      customerEmailError: {
        status: null,
        message: "",
      },
      shippingEmailError: {
        status: null,
        message: "",
      },
      shippingPhoneError: {
        status: null,
        message: "",
      },
      shippingNameError: {
        status: null,
        message: "",
      },
      test: {
        status: null,
        message: "",
      },
      countryList: ["New Zealand", "Australia", "Fiji"],
      SupplierTypeList: [
        "General",
        "Employee",
        "Contractor",
        "Preferred",
        "International",
        "One-off",
        "Tax",
        "Grants",
        "Other",
      ],
      PaymentTermList: [
        "7 days",
        "20 FOLM",
        "30 FOLM",
        "Same day",
        "Open",
        "N/A",
      ],
      fijiProvinceList: [
        "Ba",
        "Bua",
        "Cakaudrove",
        "Kadavu",
        "Lau",
        "Lomaiviti",
        "Macuata",
        "Nadroga-Navosa",
        "Naitasiri",
        "Namosi",
        "Ra",
        "Rewa",
        "Serua",
        "Tailevu",
      ],
    };
  },
  watch: {
    customerName(value) {
      if (value) {
        this.customerNameError.status = true;
      } else {
        this.customerNameError.status = false;
        this.customerNameError.message = "Please fill out this field";
      }
    },
    fullName(value) {
      if (value) {
        this.fullNameError.status = true;
      } else {
        this.fullNameError.status = false;
        this.fullNameError.message = "Please fill out this field";
      }
    },
    customerPhone(value) {
      this.validatePhoneNumber(value);
    },
    customerEmail(value) {
      this.customerEmailError.status = null;
      if (value) {
        if (CheckEmail(value) == true) this.customerEmailError.status = true;
        else {
          this.customerEmailError.status = false;
          this.customerEmailError.message =
            "Please enter a valid email address";
        }
      } else {
        this.customerEmailError.status = false;
        this.customerEmailError.message = "Please fill in your email address";
      }
    },
    shippingName(value) {
      if (value) {
        this.shippingNameError.status = true;
      } else {
        this.shippingNameError.status = false;
        this.shippingNameError.message = "Please fill out this field";
      }
    },
    shippingPhone(value) {
      this.validateShippingPhoneNumber(value);
    },
    shippingEmail(value) {
      if (value) {
        if (CheckEmail(value) == true) this.shippingEmailError.status = true;
        else {
          this.shippingEmailError.status = false;
          this.shippingEmailError.message =
            "Please enter a valid email address";
        }
      } else {
        this.shippingEmailError.status = true;
        // this.shippingEmailError.message = "Please fill in your email address";
      }
    },
    billingCountry(value) {
      console.log("billing address", value);
      this.billingAddress.addressCountry = value;
      this.validatePhoneNumber(this.customerPhone, value);
    },
    shippingCountry(value) {
      console.log("shipping address", value);
      this.shippingAddress.addressCountry = value;
      this.validateShippingPhoneNumber(this.shippingPhone, value);
    },
  },
  computed: {
    filteredProvinceListBilling() {
      let country = this.billingAddress.addressCountry || this.billingCountry;
      if (country === "New Zealand") {
        return this.NewzelandProvinceList;
      } else if (country === "Australia") {
        return this.AusiProvinceList;
      } else if (country === "Fiji") {
        return this.fijiProvinceList;
      } else {
        return [];
      }
    },
    filteredProvinceListShipping() {
      let country = this.shippingAddress.addressCountry || this.shippingCountry;
      if (country === "New Zealand") {
        return this.NewzelandProvinceList;
      } else if (country === "Australia") {
        return this.AusiProvinceList;
      } else if (country === "Fiji") {
        return this.fijiProvinceList;
      } else {
        return [];
      }
    },
    isSupplier() {
      return window.location.href.toString().includes("supplier");
    },
  },
  async mounted() {
    // await this.initFn();
    this.isEditCustomer = this.$route.meta.isEdit;
    if (this.isEditCustomer) {
      this.initEditCustomerFn(this.$route.params.customerId);
      // console.log(this.$route.params.invoiceId);
    }
  },
  methods: {
    validatePhoneNumber(value) {
      this.customerPhoneError.status = null;
      if (!value) {
        this.customerPhoneError.status = false;
        this.customerPhoneError.message = "Please fill in the contact number";
      } else {
        let ans = isValidContactNumber(
          value,
          this.billingAddress.addressCountry
        );
        if (ans === true) {
          this.customerPhoneError.status = true;
        } else {
          this.customerPhoneError.status = false;
          this.customerPhoneError.message = "Invalid contact number";
        }
      }
    },
    validateShippingPhoneNumber(value) {
      this.shippingPhoneError.status = null;
      if (!value) {
        this.shippingPhoneError.status = false;
        this.shippingPhoneError.message = "Please fill in the contact number";
      } else {
        let ans = isValidContactNumber(
          value,
          this.shippingAddress.addressCountry
        );
        if (ans === true) {
          this.shippingPhoneError.status = true;
        } else {
          this.shippingPhoneError.status = false;
          this.shippingPhoneError.message = "Invalid contact number";
        }
      }
    },
    async addCustomer() {
      if (this.isSupplier) {
        let ans1 = isValidContactNumber(
          this.customerPhone,
          this.billingAddress.addressCountry
        );
        if(
          this.customerName &&
          this.customerEmail &&
          this.fullName &&
          this.customerPhone &&
          CheckEmail(this.customerEmail) == true &&
          ans1 == true
        )
        if(this.isEditCustomer){
          let payload = {
              id: this.$route.params.customerId,
              name: this.customerName,
              full_name: this.fullName,
              email: this.customerEmail,
              phone: this.customerPhone,
              Supplier_type:this.SuplierType,
              Payment_term:this.PaymentTerm,
              billing_address: {
                number: this.billingAddress.addressNum,
                street: this.billingAddress.addressStreet,
                city: this.billingAddress.addressCity,
                zipcode: this.billingAddress.addressZip,
                province: this.billingAddress.addressProvince,
                country: this.billingAddress.addressCountry,
              },
              shipping_address: {
                number: this.shippingAddress.addressNum,
                street: this.shippingAddress.addressStreet,
                city: this.shippingAddress.addressCity,
                zipcode: this.shippingAddress.addressZip,
                province: this.shippingAddress.addressProvince,
                country: this.shippingAddress.addressCountry,
              },
              shipping_name: this.shippingName,
              shipping_phone: this.shippingPhone,
              shipping_email: this.shippingEmail,
            };
            try {
              let { data } = await UpdateSupplier(payload);
              if (data.msg == "Updated succesfully") {
                console.log("data", data);
                let payloadNotify = {
                  isToast: true,
                  title: "Supplier Update",
                  content: "The Supplier has been updated successfully",
                  variant: "success",
                };
                this.$store.dispatch("notification/setNotify", payloadNotify);
                console.log("notify", payloadNotify);
                this.$router.push("/Supplier");
              }
            } catch (error) {
              console.log(error);
            }
        }
        else{
        {
          let payload1 = {
            name: this.customerName,
            full_name: this.fullName,
            email: this.customerEmail,
            phone: this.customerPhone,
            Supplier_type:this.SuplierType,
            Payment_term:this.PaymentTerm,
            billing_address: {
              number: this.billingAddress.addressNum,
              street: this.billingAddress.addressStreet,
              city: this.billingAddress.addressCity,
              zipcode: this.billingAddress.addressZip,
              province: this.billingAddress.addressProvince,
              country: this.billingAddress.addressCountry,
            },
            shipping_address: {
              number: this.shippingAddress.addressNum,
              street: this.shippingAddress.addressStreet,
              city: this.shippingAddress.addressCity,
              zipcode: this.shippingAddress.addressZip,
              province: this.shippingAddress.addressProvince,
              country: this.shippingAddress.addressCountry,
            },
            shipping_name: this.shippingName,
            shipping_phone: this.shippingPhone,
            shipping_email: this.shippingEmail,
          };
          let payload2 = {
            name: this.customerName,
            full_name: this.fullName,
            email: this.customerEmail,
            phone: this.customerPhone,
            Supplier_type:this.SuplierType,
            Payment_term:this.PaymentTerm,
            billing_address: {
              number: this.billingAddress.addressNum,
              street: this.billingAddress.addressStreet,
              city: this.billingAddress.addressCity,
              zipcode: this.billingAddress.addressZip,
              province: this.billingAddress.addressProvince,
              country: this.billingAddress.addressCountry,
            },
            shipping_address: {
              number: this.billingAddress.addressNum,
              street: this.billingAddress.addressStreet,
              city: this.billingAddress.addressCity,
              zipcode: this.billingAddress.addressZip,
              province: this.billingAddress.addressProvince,
              country: this.billingAddress.addressCountry,
            },
            shipping_name: this.fullName,
            shipping_phone: this.customerPhone,
            shipping_email: this.customerEmail,
          };
          let payload;
          if (this.shippingStatus == "isShipping") {
            payload = payload1;
          } else {
            payload = payload2;
          }
          try {
            let { data, status } = await AddSupplier(payload);
            console.log("Add", data);
              if (status == 200) {
                let payloadNotify = {
                  isToast: true,
                  title: "Supplier Create",
                  content: "The Supplier has been created successfully",
                  variant: "success",
                };
                this.$store.dispatch("notification/setNotify", payloadNotify);
                this.$router.push("/supplier");
              } else {
                console.log("Server Error");
              }
          }
          catch (error) {
            console.log(error);
          }
        }
        } else {
          this.validateForm();
        }
      }
      else {
        // console.log("clicked");
        let ans1 = isValidContactNumber(
          this.customerPhone,
          this.billingAddress.addressCountry
        );
        //let ans2 = isValidContactNumber(this.shippingPhone, this.shippingAddress.addressCountry);
        if (
          this.customerName &&
          this.customerEmail &&
          this.fullName &&
          this.customerPhone &&
          CheckEmail(this.customerEmail) == true &&
          ans1 == true
        ) {
          if (this.isEditCustomer) {
            let payload = {
              id: this.$route.params.customerId,
              name: this.customerName,
              full_name: this.fullName,
              email: this.customerEmail,
              phone: this.customerPhone,
              billing_address: {
                number: this.billingAddress.addressNum,
                street: this.billingAddress.addressStreet,
                city: this.billingAddress.addressCity,
                zipcode: this.billingAddress.addressZip,
                province: this.billingAddress.addressProvince,
                country: this.billingAddress.addressCountry,
              },
              shipping_address: {
                number: this.shippingAddress.addressNum,
                street: this.shippingAddress.addressStreet,
                city: this.shippingAddress.addressCity,
                zipcode: this.shippingAddress.addressZip,
                province: this.shippingAddress.addressProvince,
                country: this.shippingAddress.addressCountry,
              },
              shipping_name: this.shippingName,
              shipping_phone: this.shippingPhone,
              shipping_email: this.shippingEmail,
            };
            try {
              let { data } = await UpdateCustomer(payload);
              if (data.msg == "Updated succesfully") {
                console.log("data", data);
                let payloadNotify = {
                  isToast: true,
                  title: "Customer Update",
                  content: "The customer has been updated successfully",
                  variant: "success",
                };
                this.$store.dispatch("notification/setNotify", payloadNotify);
                console.log("notify", payloadNotify);
                this.$router.push("/customers");
              }
            } catch (error) {
              console.log(error);
            }
          } else {
            let payload1 = {
              name: this.customerName,
              full_name: this.fullName,
              email: this.customerEmail,
              phone: this.customerPhone,
              billing_address: {
                number: this.billingAddress.addressNum,
                street: this.billingAddress.addressStreet,
                city: this.billingAddress.addressCity,
                zipcode: this.billingAddress.addressZip,
                province: this.billingAddress.addressProvince,
                country: this.billingAddress.addressCountry,
              },
              shipping_address: {
                number: this.shippingAddress.addressNum,
                street: this.shippingAddress.addressStreet,
                city: this.shippingAddress.addressCity,
                zipcode: this.shippingAddress.addressZip,
                province: this.shippingAddress.addressProvince,
                country: this.shippingAddress.addressCountry,
              },
              shipping_name: this.shippingName,
              shipping_phone: this.shippingPhone,
              shipping_email: this.shippingEmail,
            };
            let payload2 = {
              name: this.customerName,
              full_name: this.fullName,
              email: this.customerEmail,
              phone: this.customerPhone,
              billing_address: {
                number: this.billingAddress.addressNum,
                street: this.billingAddress.addressStreet,
                city: this.billingAddress.addressCity,
                zipcode: this.billingAddress.addressZip,
                province: this.billingAddress.addressProvince,
                country: this.billingAddress.addressCountry,
              },
              shipping_address: {
                number: this.billingAddress.addressNum,
                street: this.billingAddress.addressStreet,
                city: this.billingAddress.addressCity,
                zipcode: this.billingAddress.addressZip,
                province: this.billingAddress.addressProvince,
                country: this.billingAddress.addressCountry,
              },
              shipping_name: this.fullName,
              shipping_phone: this.customerPhone,
              shipping_email: this.customerEmail,
            };
            let payload;
            if (this.shippingStatus == "isShipping") {
              payload = payload1;
            } else {
              payload = payload2;
            }
            console.log("create payload", payload);
            try {
              let { data, status } = await AddCustomer(payload);
              console.log("Add", data);
              if (status == 200) {
                let payloadNotify = {
                  isToast: true,
                  title: "Customer Create",
                  content: "The customer has been created successfully",
                  variant: "success",
                };
                this.$store.dispatch("notification/setNotify", payloadNotify);
                this.$router.push("/customers");
              } else {
                console.log("Server Error");
              }
            } catch (error) {
              console.log(error);
            }
          }
        } else {
          this.validateForm();
        }
      }
    },
    validateForm() {
      if (
        !this.customerName ||
        !this.customerEmail ||
        !this.fullName ||
        !this.customerPhone
      ) {
        console.log("Hellooooooooo")
        if (!this.customerName) {
          this.customerNameError.status = false;
          this.customerNameError.message = "Please fill out this field";
          let payloadNotify = {
            isToast: true,
            title: "Error: Unable to save the Customer",
            content: "Please enter the the customer name",
            variant: "danger",
          };
          this.$store.dispatch("notification/setNotify", payloadNotify);
        }
        if (!this.fullName) {
          this.fullNameError.status = false;
          this.fullNameError.message = "Please fill out this field";
          let payloadNotify = {
            isToast: true,
            title: "Error: Unable to save the Customer",
            content: "Please enter the the Full name",
            variant: "danger",
          };
          this.$store.dispatch("notification/setNotify", payloadNotify);
        }
        if (!this.customerPhone) {
          this.customerPhoneError.status = false;
          this.customerPhoneError.message = "Please fill out this field";
          let payloadNotify = {
            isToast: true,
            title: "Error: Unable to save the Customer",
            content: "Please enter the the Contact Number",
            variant: "danger",
          };
          this.$store.dispatch("notification/setNotify", payloadNotify);
        }
        if (!this.customerEmail) {
          this.customerEmailError.status = false;
          this.customerEmailError.message = "Please fill out this field";
          let payloadNotify = {
            isToast: true,
            title: "Error: Unable to save the Customer",
            content: "Please enter the the Email",
            variant: "danger",
          };
          this.$store.dispatch("notification/setNotify", payloadNotify);
        }
        let payloadNotify = {
          isToast: true,
          title: "Error: Unable to save the Customer",
          content: "Please fill out the fields",
          variant: "danger",
        };
        this.$store.dispatch("notification/setNotify", payloadNotify);
      } else if (CheckEmail(this.customerEmail) == false) {
        let payloadNotify = {
          isToast: true,
          title: "Error: Unable to save the Customer",
          content: "Please enter a valid email",
          variant: "danger",
        };
        this.$store.dispatch("notification/setNotify", payloadNotify);
      } else {
        if (!this.customerName) {
          this.customerNameError.status = false;
          this.customerNameError.message = "Please fill out this field";
          let payloadNotify = {
            isToast: true,
            title: "Error: Unable to save the Customer",
            content: "Please enter the the customer name",
            variant: "danger",
          };
          this.$store.dispatch("notification/setNotify", payloadNotify);
        }
        if (!this.fullName) {
          this.fullNameError.status = false;
          this.fullNameError.message = "Please fill out this field";
          let payloadNotify = {
            isToast: true,
            title: "Error: Unable to save the Customer",
            content: "Please enter the the Full name",
            variant: "danger",
          };
          this.$store.dispatch("notification/setNotify", payloadNotify);
        }
        if (!this.customerPhone) {
          this.customerPhoneError.status = false;
          this.customerPhoneError.message = "Please fill out this field";
          let payloadNotify = {
            isToast: true,
            title: "Error: Unable to save the Customer",
            content: "Please enter the the Contact Number",
            variant: "danger",
          };
          this.$store.dispatch("notification/setNotify", payloadNotify);
        }
        if (!this.customerEmail) {
          this.customerEmailError.status = false;
          this.customerEmailError.message = "Please fill out this field";
          let payloadNotify = {
            isToast: true,
            title: "Error: Unable to save the Customer",
            content: "Please enter the the Email",
            variant: "danger",
          };
          this.$store.dispatch("notification/setNotify", payloadNotify);
        }
        let ans1 = isValidContactNumber(
          this.customerPhone,
          this.billingAddress.addressCountry
        );
        let ans2 = isValidContactNumber(
          this.shippingPhone,
          this.shippingAddress.addressCountry
        );
        if (ans1 == false || ans2 == false) {
          this.customerPhoneError.status = false;
          this.customerPhoneError.message = "Invalid contact number";
          let payloadNotify = {
            isToast: true,
            title: "Error: Unable to save the Customer",
            content: "Contact number does not match with the country",
            variant: "danger",
          };
          this.$store.dispatch("notification/setNotify", payloadNotify);
        }
      }
    },
    async initEditCustomerFn(val) {
      if(this.isSupplier){
        try {
        let { data } = await GetSingleSupplier(val);
        console.log("get single", data);
        this.customerName = data.name;
        (this.fullName = data.full_name),
          (this.customerPhone = data.phone),
          (this.customerEmail = data.email),
          (this.SuplierType = data.Supplier_type),
          (this.PaymentTerm = data.Payment_term),
          (this.billingAddress = {
            addressNum: data.billing_address.number,
            addressStreet: data.billing_address.street,
            addressCity: data.billing_address.city,
            addressZip: data.billing_address.zipcode,
            addressProvince: data.billing_address.province,
            addressCountry: data.billing_address.country,
          }),
          (this.billingCountry = data.billing_address.country),
          (this.shippingAddress = {
            addressNum: data.shipping_address.number,
            addressStreet: data.shipping_address.street,
            addressCity: data.shipping_address.city,
            addressZip: data.shipping_address.zipcode,
            addressProvince: data.shipping_address.province,
            addressCountry: data.shipping_address.country,
          }),
          (this.shippingCountry = data.shipping_address.country),
          (this.shippingName = data.shipping_name),
          (this.shippingPhone = data.shipping_phone),
          (this.shippingEmail = data.shipping_email);
        console.log("address", this.billingAddress.addressNum);
        if (
          this.billingAddress.addressNum !== this.shippingAddress.addressNum ||
          this.billingAddress.addressStreet !==
            this.shippingAddress.addressStreet ||
          this.billingAddress.addressCity !==
            this.shippingAddress.addressCity ||
          this.billingAddress.addressZip !== this.shippingAddress.addressZip ||
          this.billingAddress.addressProvince !==
            this.shippingAddress.addressProvince
        ) {
          this.shippingStatus = "isShipping";
        }
      } catch (error) {
        console.log(error);
      }
      }
      else{
      try {
        let { data } = await GetSingleCustomer(val);
        console.log("get single", data);
        this.customerName = data.name;
        (this.fullName = data.full_name),
          (this.customerPhone = data.phone),
          (this.customerEmail = data.email),
          (this.billingAddress = {
            addressNum: data.billing_address.number,
            addressStreet: data.billing_address.street,
            addressCity: data.billing_address.city,
            addressZip: data.billing_address.zipcode,
            addressProvince: data.billing_address.province,
            addressCountry: data.billing_address.country,
          }),
          (this.billingCountry = data.billing_address.country),
          (this.shippingAddress = {
            addressNum: data.shipping_address.number,
            addressStreet: data.shipping_address.street,
            addressCity: data.shipping_address.city,
            addressZip: data.shipping_address.zipcode,
            addressProvince: data.shipping_address.province,
            addressCountry: data.shipping_address.country,
          }),
          (this.shippingCountry = data.shipping_address.country),
          (this.shippingName = data.shipping_name),
          (this.shippingPhone = data.shipping_phone),
          (this.shippingEmail = data.shipping_email);
        console.log("address", this.billingAddress.addressNum);
        if (
          this.billingAddress.addressNum !== this.shippingAddress.addressNum ||
          this.billingAddress.addressStreet !==
            this.shippingAddress.addressStreet ||
          this.billingAddress.addressCity !==
            this.shippingAddress.addressCity ||
          this.billingAddress.addressZip !== this.shippingAddress.addressZip ||
          this.billingAddress.addressProvince !==
            this.shippingAddress.addressProvince
        ) {
          this.shippingStatus = "isShipping";
        }
      } catch (error) {
        console.log(error);
      }
    }
    },
    cancel() {
      if(this.isSupplier){
        this.$router.push("/supplier");
      }else{
        this.$router.push("/customers");
      }
      
    },
  },
};
</script>
<style scoped>
.custom-control-input:checked ~ .custom-control-label::before {
  color: #ffffff;
  border-color: #0b9b7d;
  background-color: #0b9b7d;
  height: 16px;
  width: 16px;
}

.add-customer-view span {
  font-size: 13px;
}

.add-customer-view input {
  height: 25px !important;
  min-height: 25px !important;
  font-size: 12px !important;
}

/* table card */
.table-card-row {
  height: 100%;
}
.table-card-row .table-container-card {
  background-color: var(--white);
  width: 100%;
  min-height: 920px;
  border-radius: 5px;
  border: 1px solid var(--light);
}
/* table actions row */
.table-card-row .search-input-group {
  box-shadow: none;
  height: 50px;
  max-width: 350px;
}
.table-card-row .search-input-group .search-input {
  box-shadow: none;
  height: 50px;
}
.table-card-row .search-input-group .search-input::placeholder {
  color: var(--prime-gray);
}
.table-card-row .filter-dropdown,
.table-card-row .add-question-btn {
  height: 45px;
}
.table-card-row .filter-dropdown >>> .filter-dropdown-menu {
  height: auto;
  min-width: 200px;
  max-height: 50vh;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
}
.table-card-row
  .filter-dropdown
  >>> .filter-dropdown-menu
  button.dropdown-item.active,
.table-card-row
  .filter-dropdown
  >>> .filter-dropdown-menu
  button.dropdown-item:active {
  background-color: var(--light);
}
/* table container */
.table-container-row {
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: thin;
}
/* scrollbar size fix for webkit browsers (chrome/safari) */
.table-container-row::-webkit-scrollbar {
  height: 0.5rem;
  scrollbar-width: thin;
}
.table-container-row::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f0f0f0;
}
.table-container-row::-webkit-scrollbar-thumb {
  background-color: #cdcdcd;
}
.table-container-row .table-element {
  min-width: 1000px;
}
/* table element css */
.table-element >>> .table-header {
  /* background-color: #f7f7ff; */
  background-color: transparent;
}
.table-element >>> .table-header th {
  color: var(--prime-gray);
  font-size: 0.8rem;
  font-weight: 400;
  border-color: transparent;
}
.table-element >>> .table-body td {
  vertical-align: middle;
}
.table-element >>> .table-body tr:nth-child(even) {
  vertical-align: middle;
  background-color: var(--light);
}
.table-element >>> .table-body .main-text {
  font-size: 0.9rem;
}
.table-element >>> .table-body .sub-text {
  font-size: 0.7rem;
}
/* question row */
.table-element >>> .table-body .question-row {
  max-width: 550px;
}
/* actions row */
.table-element >>> .table-body .actions-row {
  min-width: 140px;
}
/* more icon button (default state) */
.table-element >>> .table-body .more-btn {
  width: 35px;
  height: 35px;
  padding: 7px;
  background-color: var(--secondary);
  transition: background-color 200ms ease-in;
}
.table-element >>> .table-body .more-btn > .more-icon {
  color: var(--white);
  transition: color 200ms ease-in;
}
/* more icon button (hover state) */
.table-element >>> .table-body .more-btn:hover {
  background-color: var(--prime-gray);
}
.table-element >>> .table-body .more-btn:hover > .more-icon {
  color: var(--white);
}
/* table question action buttons */
/* edit icon button (default state) */
.table-element >>> .table-body .edit-btn {
  width: 40px;
  height: 40px;
  padding: 7px;
  background-color: var(--white);
  transition: background-color 200ms ease-in;
}
.table-element >>> .table-body .edit-btn > .edit-icon {
  color: #0b9b7d;
  transition: color 200ms ease-in;
}
/* edit icon button (hover state) */
.table-element >>> .table-body .edit-btn:hover {
  background-color: #0b9b7d;
}
.table-element >>> .table-body .edit-btn:hover > .edit-icon {
  color: var(--white);
}
/* delete icon button (default state) */
.table-element >>> .table-body .delete-btn {
  width: 40px;
  height: 40px;
  background-color: var(--white);
  transition: background-color 200ms ease-in;
}
.table-element >>> .table-body .delete-btn > .delete-icon {
  color: var(--danger);
  transition: color 200ms ease-in;
}
/* delete icon button (hover state) */
.table-element >>> .table-body .delete-btn:hover {
  background-color: var(--danger);
}
.table-element >>> .table-body .delete-btn:hover > .delete-icon {
  color: var(--white);
}
/* expand icon button (default state) */
.table-element >>> .table-body .expand-btn {
  width: 40px;
  height: 40px;
  padding: 7px;
  background-color: var(--white);
  transition: background-color 200ms ease-in;
}
.table-element >>> .table-body .expand-btn > .expand-icon {
  color: var(--prime-gray);
  transition: color 200ms ease-in;
}
/* expand icon button (hover state) */
.table-element >>> .table-body .expand-btn:hover {
  background-color: var(--prime-gray);
}
.table-element >>> .table-body .expand-btn:hover > .expand-icon {
  color: var(--white);
}
.table-element >>> .table-body .b-table-details {
  background-color: #fff;
}
.table-element >>> .table-body .b-table-details:hover {
  cursor: auto;
  background-color: #fff;
}
/* table per page selector */
.per-page-select {
  cursor: pointer;
  min-width: 65px;
}
/* table pagination */
.table-pagination >>> .custom-page-item button,
.table-pagination >>> .custom-page-item span {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--dark);
  background-color: transparent;
  border-color: transparent;
  border-radius: 5px !important;
  width: 26px;
  height: 26px;
  box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.15);
}
.table-pagination >>> .custom-page-item button:hover {
  color: var(--secondary);
  border-color: var(--secondary);
  background-color: var(--light);
}
.table-pagination >>> .custom-page-item.active button,
.table-pagination >>> .custom-page-item.active span {
  color: var(--light);
  border-color: var(--prime-gray);
  background-color: var(--prime-gray);
}
.table-pagination >>> .custom-page-item.disabled button,
.table-pagination >>> .custom-page-item.disabled span {
  color: var(--prime-gray);
  border-color: transparent;
  background-color: var(--ghost-white);
}
.action-btn {
  height: 50px;
  min-width: 120px;
}

.action-btn-save {
  height: 50px;
  min-width: 120px;
}

.action-btn-save:hover {
  background: #0b9b7d;
  box-shadow: inset 0px 0px 10px #ffffff;
}
/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .table-card-row .search-input-group {
    max-width: none;
  }
  .action-btn {
    width: 80%;
  }
}
</style>
